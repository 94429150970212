import React from 'react';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SecurityIcon from '@mui/icons-material/Security';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import ShieldIcon from '@mui/icons-material/Shield';
import HttpsIcon from '@mui/icons-material/Https';
import PolicyIcon from '@mui/icons-material/Policy';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import MonitoringIcon from '@mui/icons-material/Visibility';
import WarningIcon from '@mui/icons-material/Warning';
import BackupIcon from '@mui/icons-material/Backup';
import UpdateIcon from '@mui/icons-material/Update';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SchoolIcon from '@mui/icons-material/School';
import { useNavigate } from 'react-router-dom';

const securityServices = [
  {
    title: 'Network Security Assessment',
    icon: <SecurityIcon />,
    description: 'Comprehensive network security evaluation and risk assessment',
    details: [
      'Vulnerability scanning and assessment',
      'Security architecture review',
      'Risk analysis and reporting',
      'Compliance evaluation',
      'Security gap analysis',
      'Remediation recommendations'
    ]
  },
  {
    title: 'Firewall Management',
    icon: <ShieldIcon />,
    description: 'Advanced firewall configuration and management services',
    details: [
      'Firewall deployment and configuration',
      'Rule set optimization',
      'Traffic monitoring and analysis',
      'Security policy implementation',
      'Performance tuning',
      'Regular maintenance and updates'
    ]
  },
  {
    title: 'VPN Solutions',
    icon: <VpnLockIcon />,
    description: 'Secure remote access and site-to-site VPN solutions',
    details: [
      'VPN infrastructure setup',
      'Remote access configuration',
      'Site-to-site VPN deployment',
      'Encryption implementation',
      'Access control management',
      'Performance optimization'
    ]
  },
  {
    title: 'Endpoint Security',
    icon: <HttpsIcon />,
    description: 'Comprehensive endpoint protection and management',
    details: [
      'Antivirus deployment and management',
      'Endpoint detection and response',
      'Device control policies',
      'Data loss prevention',
      'Mobile device management',
      'Security patch management'
    ]
  },
  {
    title: 'Security Policy Management',
    icon: <PolicyIcon />,
    description: 'Development and implementation of security policies',
    details: [
      'Security policy development',
      'Compliance framework alignment',
      'Policy documentation',
      'Implementation guidelines',
      'Regular policy reviews',
      'User awareness training'
    ]
  },
  {
    title: 'Access Control Management',
    icon: <AdminPanelSettingsIcon />,
    description: 'Robust access control and identity management',
    details: [
      'Identity and access management',
      'Role-based access control',
      'Multi-factor authentication',
      'Privileged access management',
      'User lifecycle management',
      'Access audit and reporting'
    ]
  },
  {
    title: 'Security Monitoring',
    icon: <MonitoringIcon />,
    description: '24/7 security monitoring and threat detection',
    details: [
      'Real-time threat monitoring',
      'Security event analysis',
      'Incident detection and response',
      'Log management and analysis',
      'Security metrics reporting',
      'Threat intelligence integration'
    ]
  },
  {
    title: 'Incident Response',
    icon: <WarningIcon />,
    description: 'Professional security incident response and management',
    details: [
      'Incident response planning',
      'Emergency response procedures',
      'Threat containment',
      'Forensic analysis',
      'Recovery assistance',
      'Post-incident analysis'
    ]
  },
  {
    title: 'Data Backup & Recovery',
    icon: <BackupIcon />,
    description: 'Secure data backup and disaster recovery solutions',
    details: [
      'Backup strategy development',
      'Secure backup implementation',
      'Recovery testing',
      'Data encryption',
      'Retention policy management',
      'Disaster recovery planning'
    ]
  },
  {
    title: 'Security Updates & Patching',
    icon: <UpdateIcon />,
    description: 'Systematic security update and patch management',
    details: [
      'Patch management strategy',
      'Security update deployment',
      'Vulnerability remediation',
      'Testing and validation',
      'Rollback planning',
      'Update reporting'
    ]
  },
  {
    title: 'Security Compliance',
    icon: <AssessmentIcon />,
    description: 'Ensuring compliance with security standards and regulations',
    details: [
      'Compliance assessment',
      'Regulatory requirement mapping',
      'Control implementation',
      'Audit preparation',
      'Documentation management',
      'Continuous compliance monitoring'
    ]
  },
  {
    title: 'Security Training',
    icon: <SchoolIcon />,
    description: 'Comprehensive security awareness training programs',
    details: [
      'Security awareness training',
      'Phishing simulation exercises',
      'Policy and procedure training',
      'Incident response drills',
      'Best practices education',
      'Training effectiveness assessment'
    ]
  }
];

const NetworkSecurity = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Box sx={{ mb: 6 }}>
        <Typography variant="h2" component="h1" gutterBottom>
          Network Security Services
        </Typography>
        <Typography variant="h5" color="text.secondary" paragraph>
          Comprehensive network security solutions to protect your digital assets
        </Typography>
      </Box>

      <Grid container spacing={4}>
        {securityServices.map((service, index) => (
          <Grid item xs={12} key={index}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  backgroundColor: 'primary.light',
                  '&:hover': {
                    backgroundColor: 'primary.main',
                    '& .MuiTypography-root': {
                      color: 'white',
                    },
                    '& .MuiSvgIcon-root': {
                      color: 'white',
                    },
                  },
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <Box sx={{ mr: 2, color: 'primary.main' }}>
                    {service.icon}
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography variant="h6">{service.title}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      {service.description}
                    </Typography>
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {service.details.map((detail, idx) => (
                    <ListItem key={idx}>
                      <ListItemIcon>
                        <CheckCircleOutlineIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary={detail} />
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ mt: 8, textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Ready to Secure Your Network?
        </Typography>
        <Typography variant="body1" paragraph>
          Contact us today to learn how our network security services can protect your business.
        </Typography>
        <Box sx={{ mt: 4 }}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={() => navigate('/it-services')}
            sx={{ mr: 2 }}
          >
            Back to Services
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => navigate('/contact')}
          >
            Contact Us
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default NetworkSecurity;
