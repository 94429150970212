import React from 'react';
import { Container, Typography, Grid, Card, CardContent, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ComputerIcon from '@mui/icons-material/Computer';
import SchoolIcon from '@mui/icons-material/School';
import SupportIcon from '@mui/icons-material/Support';

const Home = () => {
  const navigate = useNavigate();

  const services = [
    {
      title: 'Managed IT Services',
      description: 'Comprehensive IT solutions to keep your business running smoothly',
      icon: <ComputerIcon sx={{ fontSize: 40 }} />,
      link: '/it-services'
    },
    {
      title: 'eLearning Solutions',
      description: 'Custom learning management systems and content development',
      icon: <SchoolIcon sx={{ fontSize: 40 }} />,
      link: '/elearning'
    },
    {
      title: '24/7 Technical Support',
      description: 'Round-the-clock assistance for all your IT needs',
      icon: <SupportIcon sx={{ fontSize: 40 }} />,
      link: '/contact'
    }
  ];

  return (
    <>
      <Box
        sx={{
          bgcolor: 'primary.main',
          color: 'white',
          py: 8,
          mb: 6
        }}
      >
        <Container maxWidth="lg">
          <Typography variant="h2" component="h1" gutterBottom>
            Transform Your Business with Modern IT Solutions
          </Typography>
          <Typography variant="h5" component="p" gutterBottom>
            Comprehensive IT Services & eLearning Solutions for the Digital Age
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => navigate('/contact')}
            sx={{ mt: 2 }}
          >
            Get Started
          </Button>
        </Container>
      </Box>

      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {services.map((service, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    transition: 'transform 0.3s ease-in-out'
                  }
                }}
              >
                <CardContent sx={{ flexGrow: 1, textAlign: 'center' }}>
                  {service.icon}
                  <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 2 }}>
                    {service.title}
                  </Typography>
                  <Typography variant="body1" color="text.secondary" paragraph>
                    {service.description}
                  </Typography>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => navigate(service.link)}
                  >
                    Learn More
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Box sx={{ my: 8, textAlign: 'center' }}>
          <Typography variant="h3" component="h2" gutterBottom>
            Why Choose Us?
          </Typography>
          <Typography variant="body1" paragraph>
            With years of experience in IT services and eLearning solutions, we provide cutting-edge 
            technology solutions that help businesses thrive in the digital age. Our team of experts 
            is dedicated to delivering exceptional service and support to meet your unique needs.
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default Home;
