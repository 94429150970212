import React from 'react';
import {
  Container,
  Typography,
  Grid,
  Button,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloudIcon from '@mui/icons-material/Cloud';
import StorageIcon from '@mui/icons-material/Storage';
import SecurityIcon from '@mui/icons-material/Security';
import BackupIcon from '@mui/icons-material/Backup';
import SyncIcon from '@mui/icons-material/Sync';
import SpeedIcon from '@mui/icons-material/Speed';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SupportIcon from '@mui/icons-material/Support';
import BuildIcon from '@mui/icons-material/Build';
import SchoolIcon from '@mui/icons-material/School';
import { useNavigate } from 'react-router-dom';

const cloudServices = [
  {
    title: 'Cloud Migration Services',
    icon: <CloudIcon />,
    description: 'Seamless transition of your applications and infrastructure to the cloud',
    details: [
      'Cloud readiness assessment',
      'Migration strategy development',
      'Data migration planning and execution',
      'Application modernization',
      'Legacy system migration',
      'Post-migration optimization'
    ]
  },
  {
    title: 'Cloud Infrastructure Management',
    icon: <StorageIcon />,
    description: 'Comprehensive management of your cloud infrastructure resources',
    details: [
      'Resource provisioning and scaling',
      'Performance monitoring',
      'Cost optimization',
      'Infrastructure automation',
      'Multi-cloud management',
      'Cloud governance implementation'
    ]
  },
  {
    title: 'Cloud Security Solutions',
    icon: <SecurityIcon />,
    description: 'Advanced security measures to protect your cloud environment',
    details: [
      'Security assessment and planning',
      'Identity and access management',
      'Data encryption',
      'Compliance management',
      'Threat detection and response',
      'Security monitoring and reporting'
    ]
  },
  {
    title: 'Cloud Backup & Recovery',
    icon: <BackupIcon />,
    description: 'Reliable backup and disaster recovery solutions in the cloud',
    details: [
      'Automated backup solutions',
      'Disaster recovery planning',
      'Data retention management',
      'Recovery testing and validation',
      'Business continuity planning',
      'Cross-region replication'
    ]
  },
  {
    title: 'Cloud Integration Services',
    icon: <IntegrationInstructionsIcon />,
    description: 'Seamless integration of cloud services with existing systems',
    details: [
      'API integration',
      'Hybrid cloud setup',
      'Application interconnectivity',
      'Data synchronization',
      'Workflow automation',
      'Integration monitoring'
    ]
  },
  {
    title: 'Cloud Performance Optimization',
    icon: <SpeedIcon />,
    description: 'Optimize your cloud resources for maximum performance',
    details: [
      'Performance monitoring',
      'Resource optimization',
      'Load balancing',
      'Auto-scaling configuration',
      'Database optimization',
      'Network performance tuning'
    ]
  },
  {
    title: 'Cloud Analytics & Monitoring',
    icon: <AnalyticsIcon />,
    description: 'Comprehensive monitoring and analytics for cloud environments',
    details: [
      'Real-time monitoring',
      'Performance analytics',
      'Cost analysis',
      'Usage reporting',
      'Predictive analytics',
      'Custom dashboard creation'
    ]
  },
  {
    title: 'DevOps & Automation',
    icon: <SyncIcon />,
    description: 'Streamline development and operations with cloud automation',
    details: [
      'CI/CD pipeline implementation',
      'Infrastructure as Code',
      'Automated deployment',
      'Container orchestration',
      'Configuration management',
      'Process automation'
    ]
  },
  {
    title: 'Cost Management',
    icon: <MonetizationOnIcon />,
    description: 'Optimize and control your cloud spending',
    details: [
      'Cost monitoring and analysis',
      'Budget planning',
      'Resource optimization',
      'Billing management',
      'Cost allocation',
      'ROI assessment'
    ]
  },
  {
    title: 'Cloud Support Services',
    icon: <SupportIcon />,
    description: '24/7 support for your cloud infrastructure',
    details: [
      'Technical support',
      'Incident management',
      'Problem resolution',
      'Performance monitoring',
      'Security monitoring',
      'Regular maintenance'
    ]
  },
  {
    title: 'Cloud Architecture Design',
    icon: <BuildIcon />,
    description: 'Expert design and planning of cloud architecture',
    details: [
      'Architecture assessment',
      'Solution design',
      'Scalability planning',
      'High availability design',
      'Security architecture',
      'Cost optimization planning'
    ]
  },
  {
    title: 'Cloud Training & Enablement',
    icon: <SchoolIcon />,
    description: 'Comprehensive training for cloud technologies',
    details: [
      'Cloud platform training',
      'Security best practices',
      'DevOps training',
      'Administration training',
      'Best practices education',
      'Certification preparation'
    ]
  }
];

const CloudSolutions = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Box sx={{ mb: 6 }}>
        <Typography variant="h2" component="h1" gutterBottom>
          Cloud Solutions
        </Typography>
        <Typography variant="h5" color="text.secondary" paragraph>
          Comprehensive cloud services to transform and optimize your business operations
        </Typography>
      </Box>

      <Grid container spacing={4}>
        {cloudServices.map((service, index) => (
          <Grid item xs={12} key={index}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  backgroundColor: 'primary.light',
                  '&:hover': {
                    backgroundColor: 'primary.main',
                    '& .MuiTypography-root': {
                      color: 'white',
                    },
                    '& .MuiSvgIcon-root': {
                      color: 'white',
                    },
                  },
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <Box sx={{ mr: 2, color: 'primary.main' }}>
                    {service.icon}
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography variant="h6">{service.title}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      {service.description}
                    </Typography>
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {service.details.map((detail, idx) => (
                    <ListItem key={idx}>
                      <ListItemIcon>
                        <CheckCircleOutlineIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary={detail} />
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ mt: 8, textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Ready to Transform Your Business with Cloud Solutions?
        </Typography>
        <Typography variant="body1" paragraph>
          Contact us today to learn how our cloud services can revolutionize your operations.
        </Typography>
        <Box sx={{ mt: 4 }}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={() => navigate('/it-services')}
            sx={{ mr: 2 }}
          >
            Back to Services
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => navigate('/contact')}
          >
            Contact Us
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default CloudSolutions;
