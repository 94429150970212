import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import {
  Container,
  Typography,
  Grid,
  TextField,
  Button,
  Box,
  Card,
  CardContent,
  Alert,
  CircularProgress,
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { TEMPLATE_ID, SERVICE_ID, PUBLIC_KEY } from '../config/emailjs';
import akdmetechBg from '../images/background.png';

const Helpdesk = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    user_phone: '',
    issue_type: '',
    message: '',
  });

  const [status, setStatus] = useState({
    submitted: false,
    submitting: false,
    info: { error: false, msg: null },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus((prevStatus) => ({ ...prevStatus, submitting: true }));

    try {
      const templateParams = {
        user_name: formData.user_name,
        user_email: formData.user_email,
        user_phone: formData.user_phone,
        issue_type: formData.issue_type,
        message: formData.message,
        to_email: 'helpdesk@akdmetech.com'
      };

      const response = await emailjs.send(
        SERVICE_ID,
        TEMPLATE_ID,
        templateParams,
        PUBLIC_KEY
      );

      setStatus({
        submitted: true,
        submitting: false,
        info: { error: false, msg: `Support ticket created successfully! Status: ${response.status} (${response.text}). Our team will contact you soon.` },
      });

      setFormData({
        user_name: '',
        user_email: '',
        user_phone: '',
        issue_type: '',
        message: '',
      });
    } catch (error) {
      console.error('Ticket creation failed:', error);
      setStatus({
        submitted: false,
        submitting: false,
        info: { error: true, msg: `Failed to create ticket: ${error.message || 'Please try again later'}` },
      });
    }
  };

  const supportInfo = [
    {
      icon: <PhoneIcon sx={{ fontSize: 40, color: '#fff' }} />,
      title: '24/7 Support',
      content: '+1 (617) 751 8481',
      style: {
        position: 'relative',
        backgroundImage: `url(${akdmetechBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        transition: 'all 0.3s ease-in-out',
        '&:before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          transition: 'background-color 0.3s ease-in-out',
          zIndex: 1
        },
        '& > *': {
          position: 'relative',
          zIndex: 2
        },
        '&:hover': {
          transform: 'translateY(-5px)',
          '&:before': {
            backgroundColor: 'rgba(25, 118, 210, 0.85)'
          }
        }
      }
    },
    {
      icon: <EmailIcon sx={{ fontSize: 40, color: '#fff' }} />,
      title: 'Support Portal',
      content: 'Akdmetech Helpdesk',
      link: 'http://helpdesk.akdmetech.com/',
      style: {
        position: 'relative',
        backgroundImage: `url(${akdmetechBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        transition: 'all 0.3s ease-in-out',
        '&:before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          transition: 'background-color 0.3s ease-in-out',
          zIndex: 1
        },
        '& > *': {
          position: 'relative',
          zIndex: 2
        },
        '&:hover': {
          transform: 'translateY(-5px)',
          '&:before': {
            backgroundColor: 'rgba(25, 118, 210, 0.85)'
          }
        }
      }
    },
    {
      icon: <LocationOnIcon sx={{ fontSize: 40, color: '#fff' }} />,
      title: 'Support Center',
      content: '102 River Street, Lynn, MA',
      style: {
        position: 'relative',
        backgroundImage: `url(${akdmetechBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        transition: 'all 0.3s ease-in-out',
        '&:before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          transition: 'background-color 0.3s ease-in-out',
          zIndex: 1
        },
        '& > *': {
          position: 'relative',
          zIndex: 2
        },
        '&:hover': {
          transform: 'translateY(-5px)',
          '&:before': {
            backgroundColor: 'rgba(25, 118, 210, 0.85)'
          }
        }
      }
    }
  ];

  return (
    <Box
      className="gradient-bg"
      sx={{
        py: { xs: 6, md: 12 },
        minHeight: '100vh',
        position: 'relative',
        overflow: 'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'var(--background-light)',
          opacity: 0.97,
        }
      }}
    >
      {/* Particle Effects */}
      <Box className="particles">
        {[...Array(8)].map((_, i) => (
          <Box
            key={i}
            className="particle"
            sx={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              animationDelay: `${i * 2}s`,
              width: `${50 + Math.random() * 100}px`,
              height: `${50 + Math.random() * 100}px`,
            }}
          />
        ))}
      </Box>

      <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
        <Box className="floating" sx={{ textAlign: 'center', mb: { xs: 6, md: 10 } }}>
          <Typography
            variant="h2"
            className="page-title"
            sx={{
              fontWeight: 800,
              fontSize: { xs: '2.5rem', md: '4rem' },
              letterSpacing: '-0.02em',
              mb: 3,
              textShadow: '0 2px 10px rgba(0,0,0,0.1)'
            }}
          >
            IT Help Desk
          </Typography>

          <Typography
            variant="h5"
            className="luxury-text"
            sx={{
              maxWidth: '800px',
              mx: 'auto',
              lineHeight: 1.6,
              fontSize: { xs: '1.1rem', md: '1.25rem' },
              px: 2,
              fontWeight: 500
            }}
          >
            Experience premium support from our dedicated team of experts, available 24/7 to assist you.
          </Typography>
        </Box>

        <Grid container spacing={4}>
          <Grid item xs={12} md={7}>
            <Card className="form-container luxury-card-hover">
              <CardContent sx={{ p: { xs: 3, md: 5 } }}>
                <form ref={form} onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography 
                        variant="h5" 
                        className="luxury-text"
                        sx={{ 
                          mb: 4, 
                          fontWeight: 700,
                          position: 'relative',
                          display: 'inline-block',
                        }}
                      >
                        Submit a Support Ticket
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        className="form-input"
                        fullWidth
                        label="Your Name"
                        name="user_name"
                        value={formData.user_name}
                        onChange={handleChange}
                        required
                        variant="outlined"
                        InputProps={{
                          sx: {
                            '&:hover': {
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--primary-light)'
                              }
                            }
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        className="form-input"
                        fullWidth
                        label="Email Address"
                        name="user_email"
                        type="email"
                        value={formData.user_email}
                        onChange={handleChange}
                        required
                        variant="outlined"
                        InputProps={{
                          sx: {
                            '&:hover': {
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--primary-light)'
                              }
                            }
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className="form-input"
                        fullWidth
                        label="Phone Number"
                        name="user_phone"
                        value={formData.user_phone}
                        onChange={handleChange}
                        required
                        variant="outlined"
                        InputProps={{
                          sx: {
                            '&:hover': {
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--primary-light)'
                              }
                            }
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className="form-input"
                        fullWidth
                        select
                        label="Issue Type"
                        name="issue_type"
                        value={formData.issue_type}
                        onChange={handleChange}
                        required
                        SelectProps={{
                          native: true,
                        }}
                        variant="outlined"
                        InputProps={{
                          sx: {
                            '&:hover': {
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--primary-light)'
                              }
                            }
                          }
                        }}
                      >
                        <option value="">Select an issue type</option>
                        <option value="Technical Support">Technical Support</option>
                        <option value="Account Issues">Account Issues</option>
                        <option value="Billing Questions">Billing Questions</option>
                        <option value="Feature Request">Feature Request</option>
                        <option value="Other">Other</option>
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className="form-input"
                        fullWidth
                        multiline
                        rows={4}
                        label="Message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                        variant="outlined"
                        InputProps={{
                          sx: {
                            '&:hover': {
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'var(--primary-light)'
                              }
                            }
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        className="submit-button"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        disabled={status.submitting}
                        sx={{ 
                          py: 2,
                          fontSize: '1.1rem'
                        }}
                      >
                        {status.submitting ? (
                          <CircularProgress 
                            size={24} 
                            color="inherit" 
                            className="loading-animation"
                          />
                        ) : (
                          'Submit Ticket'
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
                {status.info.msg && (
                  <Box mt={3} className="alert-animation">
                    <Alert 
                      severity={status.info.error ? 'error' : 'success'}
                      icon={status.info.error ? <ErrorIcon /> : <CheckCircleIcon />}
                    >
                      {status.info.msg}
                    </Alert>
                  </Box>
                )}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={5}>
            <Grid container spacing={3}>
              {supportInfo.map((info, index) => (
                <Grid item xs={12} key={index}>
                  <Card 
                    className="contact-card glass-effect luxury-card-hover"
                    sx={{
                      height: '100%',
                      backgroundImage: `url(${akdmetechBg})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                  >
                    <CardContent
                      sx={{
                        p: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        position: 'relative',
                        zIndex: 1,
                      }}
                    >
                      <Box 
                        className="contact-icon floating" 
                        mb={3}
                        sx={{
                          background: 'rgba(255, 255, 255, 0.15)',
                          p: 2.5,
                          borderRadius: '50%',
                          backdropFilter: 'blur(10px)',
                          border: '1px solid rgba(255, 255, 255, 0.2)'
                        }}
                      >
                        {info.icon}
                      </Box>
                      <Typography
                        variant="h6"
                        sx={{ 
                          color: '#fff',
                          mb: 2,
                          textAlign: 'center',
                          fontWeight: 700,
                          textShadow: '0 2px 4px rgba(0,0,0,0.2)',
                          letterSpacing: '0.5px'
                        }}
                      >
                        {info.title}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ 
                          color: '#fff',
                          textAlign: 'center',
                          opacity: 0.95,
                          textShadow: '0 1px 2px rgba(0,0,0,0.1)',
                          lineHeight: 1.6,
                          letterSpacing: '0.3px'
                        }}
                      >
                        {info.content}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Helpdesk;
