import React from 'react';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
} from '@mui/material';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import DevicesIcon from '@mui/icons-material/Devices';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CodeIcon from '@mui/icons-material/Code';
import SchoolIcon from '@mui/icons-material/School';
import { useNavigate } from 'react-router-dom';

const ELearning = () => {
  const navigate = useNavigate();

  const courseTypes = [
    {
      title: 'Technical Training',
      icon: <CodeIcon />,
      courses: [
        'Programming Languages (Python, Java, JavaScript)',
        'Web Development',
        'Cloud Computing',
        'Cybersecurity Fundamentals',
        'Database Management'
      ]
    },
    {
      title: 'Professional Certifications',
      icon: <SchoolIcon />,
      courses: [
        'CompTIA Certifications',
        'Cisco Certifications',
        'Project Management Professional (PMP)',
        'ITIL Certification',
        'Cloud Certifications (AWS, Azure)'
      ]
    }
  ];

  const developmentProcess = [
    {
      title: 'Analysis & Planning',
      description: "We analyze your organization's needs, target audience, and learning objectives to create a comprehensive course plan.",
      details: [
        'Training needs assessment',
        'Learning objectives definition',
        'Audience analysis: Identify the target audience, their needs, and preferences',
        'Content scope planning',
        'Timeline and milestone setting'
      ]
    },
    {
      title: 'Content Development',
      description: 'Our expert instructional designers create engaging and interactive content using modern learning methodologies.',
      details: [
        'Instructional design',
        'Multimedia content creation',
        'Interactive exercises',
        'Assessment development',
        'Quality assurance review'
      ]
    },
    {
      title: 'Technology Integration',
      description: 'We implement your course using cutting-edge eLearning technologies and platforms.',
      details: [
        'LMS integration',
        'Mobile optimization',
        'Interactive elements',
        'Progress tracking',
        'Analytics implementation'
      ]
    },
    {
      title: 'Testing & Launch',
      description: 'Rigorous testing and refinement ensure your course meets all objectives and quality standards.',
      details: [
        'User acceptance testing',
        'Performance optimization',
        'Pilot program',
        'Feedback integration',
        'Full-scale deployment'
      ]
    }
  ];

  const features = [
    {
      title: 'Custom Course Development',
      description: 'Tailored learning content for your organization',
      icon: <MenuBookIcon sx={{ fontSize: 40 }} />,
      benefits: [
        'Industry-specific content',
        'Interactive learning modules',
        'Multimedia integration',
        'Regular content updates'
      ]
    },
    {
      title: 'Learning Management System',
      description: 'Powerful platform for course delivery',
      icon: <DevicesIcon sx={{ fontSize: 40 }} />,
      benefits: [
        'User-friendly interface',
        'Progress tracking',
        'Mobile compatibility',
        'Cloud-based access'
      ]
    },
    {
      title: 'Analytics & Reporting',
      description: 'Track learning progress and outcomes',
      icon: <AssessmentIcon sx={{ fontSize: 40 }} />,
      benefits: [
        'Performance metrics',
        'Completion rates',
        'Assessment results',
        'Custom reports'
      ]
    }
  ];

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Typography variant="h2" component="h1" gutterBottom>
        eLearning Solutions
      </Typography>
      <Typography variant="h5" color="text.secondary" paragraph>
        Transform your training and development with our comprehensive eLearning platform
      </Typography>

      <Box sx={{ my: 6 }}>
        <Typography variant="h3" gutterBottom>
          Custom Course Development
        </Typography>
        <Typography variant="body1" paragraph>
          Our expert team creates tailored eLearning courses that meet your specific training needs and organizational goals.
        </Typography>
        
        <Typography variant="h4" sx={{ mt: 4, mb: 2 }}>
          Course Categories
        </Typography>
        <Grid container spacing={3}>
          {courseTypes.map((type, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Paper elevation={3} sx={{ p: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  {type.icon}
                  <Typography variant="h6" sx={{ ml: 2 }}>
                    {type.title}
                  </Typography>
                </Box>
                <List dense>
                  {type.courses.map((course, idx) => (
                    <ListItem key={idx}>
                      <ListItemIcon>
                        <CheckCircleIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary={course} />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Grid>
          ))}
        </Grid>

        <Typography variant="h4" sx={{ mt: 6, mb: 3 }}>
          Development Process
        </Typography>
        {developmentProcess.map((step, index) => (
          <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">{step.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography paragraph>{step.description}</Typography>
              <List dense>
                {step.details.map((detail, idx) => (
                  <ListItem key={idx}>
                    <ListItemIcon>
                      <CheckCircleIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={detail} />
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>

      <Grid container spacing={4} sx={{ mt: 4 }}>
        {features.map((feature, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Card sx={{ height: '100%' }}>
              <CardContent>
                <Box sx={{ textAlign: 'center', mb: 3 }}>
                  {feature.icon}
                  <Typography variant="h5" component="h2" sx={{ mt: 2 }}>
                    {feature.title}
                  </Typography>
                  <Typography color="text.secondary" sx={{ mb: 2 }}>
                    {feature.description}
                  </Typography>
                </Box>
                <List>
                  {feature.benefits.map((benefit, idx) => (
                    <ListItem key={idx}>
                      <ListItemIcon>
                        <CheckCircleIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary={benefit} />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ mt: 8, bgcolor: 'primary.light', p: 4, borderRadius: 2, color: 'white' }}>
        <Typography variant="h4" gutterBottom>
          Why Choose Our eLearning Platform?
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <List>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon sx={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText 
                  primary="Scalable Solutions"
                  secondary="Grow your training program as your organization expands"
                  sx={{ '& .MuiListItemText-secondary': { color: 'rgba(255, 255, 255, 0.7)' } }}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon sx={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText 
                  primary="Cost-Effective"
                  secondary="Reduce training costs while improving outcomes"
                  sx={{ '& .MuiListItemText-secondary': { color: 'rgba(255, 255, 255, 0.7)' } }}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={6}>
            <List>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon sx={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText 
                  primary="24/7 Support"
                  secondary="Expert assistance whenever you need it"
                  sx={{ '& .MuiListItemText-secondary': { color: 'rgba(255, 255, 255, 0.7)' } }}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon sx={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText 
                  primary="Regular Updates"
                  secondary="Stay current with the latest eLearning technologies"
                  sx={{ '& .MuiListItemText-secondary': { color: 'rgba(255, 255, 255, 0.7)' } }}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ mt: 8, textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Ready to Transform Your Training Program?
        </Typography>
        <Typography variant="body1" paragraph>
          Contact us today to learn how our eLearning solutions can help your organization succeed.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => navigate('/contact')}
        >
          Get Started
        </Button>
      </Box>
    </Container>
  );
};

export default ELearning;
