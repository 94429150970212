import React from 'react';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SecurityIcon from '@mui/icons-material/Security';
import StorageIcon from '@mui/icons-material/Storage';
import CloudIcon from '@mui/icons-material/Cloud';
import { useNavigate } from 'react-router-dom';

const ITServices = () => {
  const navigate = useNavigate();

  const services = [
    {
      title: 'Network Security',
      description: 'Protect your business with advanced security solutions',
      icon: <SecurityIcon sx={{ fontSize: 40 }} />,
      features: [
        'Firewall Management',
        'Endpoint Protection',
        'Security Audits',
        'Threat Monitoring'
      ]
    },
    {
      title: 'Infrastructure Management',
      description: 'Comprehensive infrastructure solutions to optimize and maintain your IT environment',
      icon: <StorageIcon sx={{ fontSize: 40 }} />,
      features: [
        'Server Management & Virtualization',
        'Network Design & Implementation',
        'Data Center Operations',
        'Storage Solutions & Backup',
        'Performance Monitoring & Optimization',
        'Infrastructure Security',
        'Disaster Recovery Planning',
        'Hardware Lifecycle Management',
        'System Integration & Updates',
        'Infrastructure Documentation',
        '24/7 Infrastructure Monitoring',
        'Capacity Planning & Scaling'
      ]
    },
    {
      title: 'Cloud Solutions',
      description: 'Leverage cloud technology for business growth',
      icon: <CloudIcon sx={{ fontSize: 40 }} />,
      features: [
        'Cloud Migration',
        'Cloud Security',
        'Backup Solutions',
        'Disaster Recovery'
      ]
    }
  ];

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Typography variant="h2" component="h1" gutterBottom>
        Managed IT Services
      </Typography>
      <Typography variant="h5" color="text.secondary" paragraph>
        Comprehensive IT solutions to help your business succeed
      </Typography>

      <Grid container spacing={4} sx={{ mt: 4 }}>
        {services.map((service, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Card sx={{ 
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}>
              <CardContent sx={{ flex: 1 }}>
                <Box sx={{ textAlign: 'center', mb: 3 }}>
                  {service.icon}
                  <Typography variant="h5" component="h2" sx={{ mt: 2 }}>
                    {service.title}
                  </Typography>
                  <Typography color="text.secondary" sx={{ mb: 2 }}>
                    {service.description}
                  </Typography>
                  {(service.title === 'Infrastructure Management' || 
                    service.title === 'Network Security' || 
                    service.title === 'Cloud Solutions') && (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => navigate(
                        service.title === 'Infrastructure Management' 
                          ? '/infrastructure' 
                          : service.title === 'Network Security'
                            ? '/network-security'
                            : '/cloud-solutions'
                      )}
                      sx={{ mt: 2 }}
                    >
                      View Details
                    </Button>
                  )}
                </Box>
                <List sx={{ 
                  maxHeight: service.features.length > 6 ? '300px' : 'auto',
                  overflowY: service.features.length > 6 ? 'auto' : 'visible',
                  '&::-webkit-scrollbar': {
                    width: '8px',
                  },
                  '&::-webkit-scrollbar-track': {
                    background: '#f1f1f1',
                    borderRadius: '4px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: '#888',
                    borderRadius: '4px',
                    '&:hover': {
                      background: '#555',
                    },
                  },
                }}>
                  {service.features.map((feature, idx) => (
                    <ListItem key={idx} sx={{ py: 0.5 }}>
                      <ListItemIcon>
                        <CheckCircleOutlineIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText 
                        primary={feature}
                        primaryTypographyProps={{
                          fontSize: '0.9rem',
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ mt: 8, textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Ready to Transform Your IT Infrastructure?
        </Typography>
        <Typography variant="body1" paragraph>
          Contact us today to learn how our managed IT services can help your business grow.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => navigate('/contact')}
        >
          Get Started
        </Button>
      </Box>
    </Container>
  );
};

export default ITServices;
