import { init } from '@emailjs/browser';

// Initialize EmailJS with your public key
const PUBLIC_KEY = "cYhsxcrm3iSePJlhz";
init(PUBLIC_KEY);

// Email template ID
export const TEMPLATE_ID = "template_lphpvk8";

// Service ID
export const SERVICE_ID = "service_owu6top";

// Export public key
export { PUBLIC_KEY };
