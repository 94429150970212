import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import ITServices from './pages/ITServices';
import ELearning from './pages/ELearning';
import Contact from './pages/Contact';
import Helpdesk from './pages/Helpdesk';
import InfrastructureDetails from './pages/InfrastructureDetails';
import NetworkSecurity from './pages/NetworkSecurity';
import CloudSolutions from './pages/CloudSolutions';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import { Box } from '@mui/material';
import backgroundImage from './images/background.png';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',
            opacity: 0.15,
            zIndex: -1,
          }
        }}
      >
        <Navbar />
        <Box
          component="main"
          sx={{
            flex: 1,
            position: 'relative',
            zIndex: 1,
          }}
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/it-services" element={<ITServices />} />
            <Route path="/infrastructure" element={<InfrastructureDetails />} />
            <Route path="/network-security" element={<NetworkSecurity />} />
            <Route path="/cloud-solutions" element={<CloudSolutions />} />
            <Route path="/elearning" element={<ELearning />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/helpdesk" element={<Helpdesk />} />
          </Routes>
        </Box>
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default App;
