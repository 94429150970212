import React from 'react';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import StorageIcon from '@mui/icons-material/Storage';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import DataCenterIcon from '@mui/icons-material/Storage';
import BackupIcon from '@mui/icons-material/Backup';
import SpeedIcon from '@mui/icons-material/Speed';
import SecurityIcon from '@mui/icons-material/Security';
import RestoreIcon from '@mui/icons-material/Restore';
import BuildIcon from '@mui/icons-material/Build';
import SystemUpdateIcon from '@mui/icons-material/SystemUpdate';
import DescriptionIcon from '@mui/icons-material/Description';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { useNavigate } from 'react-router-dom';

const infrastructureServices = [
  {
    title: 'Server Management & Virtualization',
    icon: <StorageIcon />,
    description: 'Comprehensive server management and virtualization solutions to optimize your infrastructure',
    details: [
      'Physical and virtual server management',
      'Server performance optimization',
      'Resource allocation and load balancing',
      'VMware and Hyper-V expertise',
      'Server health monitoring',
      'Capacity planning and scaling'
    ]
  },
  {
    title: 'Network Design & Implementation',
    icon: <NetworkCheckIcon />,
    description: 'Expert network architecture and deployment services',
    details: [
      'Network architecture design',
      'Infrastructure deployment',
      'Network security implementation',
      'Performance optimization',
      'Bandwidth management',
      'Network monitoring and maintenance'
    ]
  },
  {
    title: 'Data Center Operations',
    icon: <DataCenterIcon />,
    description: 'Professional data center management and optimization',
    details: [
      'Data center infrastructure management',
      'Power and cooling optimization',
      'Rack space management',
      'Cable management',
      'Environmental monitoring',
      'Physical security management'
    ]
  },
  {
    title: 'Storage Solutions & Backup',
    icon: <BackupIcon />,
    description: 'Reliable storage and backup solutions for your data',
    details: [
      'Storage system design and implementation',
      'Data backup and recovery',
      'Cloud storage integration',
      'Storage optimization',
      'Data archiving solutions',
      'Disaster recovery planning'
    ]
  },
  {
    title: 'Performance Monitoring & Optimization',
    icon: <SpeedIcon />,
    description: 'Continuous monitoring and optimization of your infrastructure',
    details: [
      'Real-time performance monitoring',
      'System health checks',
      'Performance bottleneck identification',
      'Resource utilization optimization',
      'Capacity planning',
      'Performance reporting'
    ]
  },
  {
    title: 'Infrastructure Security',
    icon: <SecurityIcon />,
    description: 'Comprehensive security solutions for your infrastructure',
    details: [
      'Security assessment and auditing',
      'Firewall management',
      'Access control implementation',
      'Security patch management',
      'Vulnerability scanning',
      'Security incident response'
    ]
  },
  {
    title: 'Disaster Recovery Planning',
    icon: <RestoreIcon />,
    description: 'Robust disaster recovery solutions to protect your business',
    details: [
      'Disaster recovery strategy development',
      'Business continuity planning',
      'Recovery testing and validation',
      'Backup site configuration',
      'Emergency response procedures',
      'Regular DR drills and updates'
    ]
  },
  {
    title: 'Hardware Lifecycle Management',
    icon: <BuildIcon />,
    description: 'Complete hardware lifecycle management services',
    details: [
      'Hardware procurement',
      'Installation and configuration',
      'Maintenance and support',
      'Performance monitoring',
      'Upgrade planning',
      'End-of-life management'
    ]
  },
  {
    title: 'System Integration & Updates',
    icon: <SystemUpdateIcon />,
    description: 'Seamless system integration and update management',
    details: [
      'System compatibility assessment',
      'Integration planning and execution',
      'Update management',
      'Version control',
      'Testing and validation',
      'Rollback planning'
    ]
  },
  {
    title: 'Infrastructure Documentation',
    icon: <DescriptionIcon />,
    description: 'Detailed documentation of your infrastructure',
    details: [
      'Network diagram maintenance',
      'Configuration documentation',
      'Process documentation',
      'Change management records',
      'Asset inventory management',
      'Recovery procedure documentation'
    ]
  },
  {
    title: '24/7 Infrastructure Monitoring',
    icon: <MonitorHeartIcon />,
    description: 'Round-the-clock monitoring of your infrastructure',
    details: [
      'Real-time system monitoring',
      'Alert management',
      'Incident response',
      'Performance tracking',
      'Availability monitoring',
      'Status reporting'
    ]
  },
  {
    title: 'Capacity Planning & Scaling',
    icon: <TrendingUpIcon />,
    description: 'Strategic capacity planning and scaling solutions',
    details: [
      'Resource utilization analysis',
      'Growth forecasting',
      'Scaling recommendations',
      'Performance impact assessment',
      'Cost optimization',
      'Implementation planning'
    ]
  }
];

const InfrastructureDetails = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Box sx={{ mb: 6 }}>
        <Typography variant="h2" component="h1" gutterBottom>
          Infrastructure Management Services
        </Typography>
        <Typography variant="h5" color="text.secondary" paragraph>
          Comprehensive infrastructure solutions to optimize and maintain your IT environment
        </Typography>
      </Box>

      <Grid container spacing={4}>
        {infrastructureServices.map((service, index) => (
          <Grid item xs={12} key={index}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  backgroundColor: 'primary.light',
                  '&:hover': {
                    backgroundColor: 'primary.main',
                    '& .MuiTypography-root': {
                      color: 'white',
                    },
                    '& .MuiSvgIcon-root': {
                      color: 'white',
                    },
                  },
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <Box sx={{ mr: 2, color: 'primary.main' }}>
                    {service.icon}
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Typography variant="h6">{service.title}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      {service.description}
                    </Typography>
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {service.details.map((detail, idx) => (
                    <ListItem key={idx}>
                      <ListItemIcon>
                        <CheckCircleOutlineIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary={detail} />
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ mt: 8, textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Ready to Optimize Your Infrastructure?
        </Typography>
        <Typography variant="body1" paragraph>
          Contact us today to learn how our infrastructure management services can transform your business.
        </Typography>
        <Box sx={{ mt: 4 }}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={() => navigate('/it-services')}
            sx={{ mr: 2 }}
          >
            Back to Services
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => navigate('/contact')}
          >
            Contact Us
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default InfrastructureDetails;
